import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import ScrollspyNav from './scrollspynav.js';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardColumns from 'react-bootstrap/CardColumns';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from "react-bootstrap/AccordionContext";
import { useContext } from "react";


    class JASONprocces extends React.Component{
        evaluateData(strg, index, content){
            switch(strg){
                case "contentEinBild": 
                    return(<div key={index}><ContentCardBigImg id={content[index].header} alt={content[index].alt} title={content[index].header} src={content[index].bild} content={content[index].content} accordion={content[index].accordion}>{content[index].zusammenfassung}</ContentCardBigImg></div>);
                case "contentNurBild":
                    return(<div key={index}><ContentOnlyImg chin={content[index].chin} src={content[index].bild} alt={content[index].alt}/></div>);
                case "contentMehrBilder":
                    return(<div key={index}><ContentMultiImage id={content[index].header} header={content[index].header} subcontent={content[index].subcontent}></ContentMultiImage></div>)
            }
        }

        render() {
            const content = this.props.content;
            var contentTextCount =0;
            var titles = [];
            for(var i=0; i < content.length; i++){
                if(content[i].typ === "contentEinBild" || content[i].typ=== "contentMehrBilder"){
                    contentTextCount = contentTextCount +1;
                    titles[contentTextCount-1] = content[i].header;
                }
            }
            const items = content.map((data ,index) =>

            this.evaluateData(data.typ, index, content)
            )
            return(<TopBar navButtons={contentTextCount} titles={titles}><Container><CardColumns>{items}</CardColumns></Container></TopBar>)
        }
    }
  
  fetch('content.json')
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      ReactDOM.render(<JASONprocces content={data}></JASONprocces>,document.getElementById("app"));
    })
    .catch(function (err) {
      console.log(err);
    })

class TopBar extends React.Component{
    render(){
        const titles = this.props.titles;
        const items = titles.map((data, index) =>
            <Navbar.Toggle as="div" aria-controls="collapsibleNavbar" key={index}>
                <Nav.Link href={"#"+data} aria-controls="collapsibleNavbar">
                    {data}
                </Nav.Link>
            </Navbar.Toggle>
        )
        return(
            <React.Fragment>
                <Navbar expand="md" fixed="top" id="navbarcollapsed">
                    <Container>
                        <Navbar.Brand as="div" className="">
                            <Row>
                            <img src="./assets/Ergovv-Logosmall.jpg" className="navbarbrandcollapsed" alt="Logo der Ergotherapiepraxis Vonnahme vom Felde"></img>
                            <div className="textTopbar mr-auto"><span className="blue">Ergo</span><span className="green">therapie</span><br className="navbreak2" /><span className="gray">   Von<br className="navbreak3"></br>nahme vom Felde</span></div>
                            </Row>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="collapsibleNavbar" className="ml-auto"><span className="material-icons">menu</span></Navbar.Toggle>
                        <Navbar.Collapse className="justify-content-end" id="collapsibleNavbar">
                        <Nav>
                            <ScrollspyNav
                                scrollTargetIds={titles}
                                activeNavClass="active"
                                scrollDuration="750"
                                offset={-100}
                            >
                                {items}
                            </ScrollspyNav>
                        </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            {this.props.children}
            </React.Fragment>
        )
    }
}

class ContentCardBigImg extends React.Component{
    constructor(props){
        super(props);
        this.state = {modalExpanded: false};
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        this.setState({modalExpanded: true});
    }

    render(){

        return(
            <Card className="card_content" id={this.props.id}>
                <Card.Header>{this.props.title}</Card.Header>
                <Card.Img className="linked" variant="top" alt={this.props.alt} src={"./assets/"+this.props.src+".jpg"} onClick={this.handleClick}/>
                <Card.Body>
                <RenderParagraph link={<Overlay accordion={this.props.accordion} src={this.props.src} alt={this.props.alt} content={this.props.content} title={this.props.title} textshort={this.props.children} modalExpanded={this.state.modalExpanded}/>}>{this.props.children}</RenderParagraph>
                </Card.Body>
            </Card>
        );
    }
}

class Overlay extends React.Component{
    render(){
        if(this.props.accordion){
            return(
                <OverlayTeam textshort={this.props.textshort} alt={this.props.alt} src={this.props.src} content={this.props.content} title={this.props.title} modalExpanded={this.props.modalExpanded}/>
            )
        } else{
            return(
                <OverlayInfo src={this.props.src} alt={this.props.alt} content={this.props.content} title={this.props.title} textshort={this.props.textshort} modalExpanded={this.props.modalExpanded}/>
            )
        }
    }
}


class ContentMultiImage extends React.Component{
    render(){
        const subcontent = this.props.subcontent;
        const items = subcontent.map((content ,index) =>
        <React.Fragment key={index}>
        <Card.Body className="card-body-multimg">
            <Row>
                <Col xs="9">
                        <h3>{content.titel}</h3>
                        <RenderParagraph link={<Overlay accordion={content.accordion} title={content.titel} alt={content.alt} content={content.content} src={content.bild} textshort={content.zusammenfassung}/>}>
                            {content.zusammenfassung}
                        </RenderParagraph>
                </Col>
                <Col xs="3">
                    <Card.Img src={"./assets/"+content.bild+"thumb.jpg"} className="img-thumbnail" alt={content.alt}/>
                </Col>
            </Row>
        </Card.Body>
        </React.Fragment>  
        )
        return(
            <Card id={this.props.id}>
                <Card.Header>{this.props.header}</Card.Header>
                {items}
            </Card>
        );
    }
}


class OverlayInfo extends React.Component{

    render(){
        return(
            <ModalInformation title={this.props.title} modalExpanded={this.props.modalExpanded}>
                <RenderParagraph>{this.props.textshort}</RenderParagraph>
                <img src={"./assets/"+this.props.src+".jpg"} className="img-overlay" alt={this.props.alt}/>
                <ParseText>{this.props.content}</ParseText>
            </ModalInformation>
        );
    }
}

function ContextAwareHeader({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <Card.Header onClick={decoratedOnClick} className={isCurrentEventKey ? "AccordionHeader" : "AccordionHeader collapsed"} >{children}</Card.Header>
    );
}

class OverlayTeam extends React.Component{
    render(){
        const content = this.props.content;
        const result = content.map((data, index) =>
            <Card key={index} className="accordionCard">
                <ContextAwareHeader eventKey={index.toString()}>
                    {data.titel}
                </ContextAwareHeader>
                <Accordion.Collapse eventKey={index.toString()}>
                    <Card.Body className="accordionCardBody">
                        <img src={"./assets/"+data.bild+".jpg"} className="img-overlay" alt={data.alt}/>
                        <ParseText>{data.text}</ParseText>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
        return(
            <ModalInformation title={this.props.title} modalExpanded={this.props.modalExpanded}>
                <RenderParagraph>{this.props.textshort}</RenderParagraph>
                <img src={"./assets/"+this.props.src+".jpg"} className="img-overlay" alt={this.props.alt}/>
                <Accordion>
                    {result}
                </Accordion>
            </ModalInformation>
        )
    }
}

class ParseText extends React.Component{
    evaluateData(strg, index, content){
        switch(strg){
            case "absatz":
                return(<React.Fragment key={index}><RenderParagraph>{content[index].inhalt}</RenderParagraph></React.Fragment>)
            case "ueberschrift":
                return(<React.Fragment key={index}><h3>{content[index].inhalt}</h3></React.Fragment>)
            case "liste":
                return(<React.Fragment key={index}><ListContent content={content[index].inhalt}></ListContent></React.Fragment>)
            case "unterüberschrift":
                return(<React.Fragment key={index}><b>{content[index].inhalt}</b></React.Fragment>)
            case "link":
                if(content[index].textunter !== undefined){
                    return(<React.Fragment key={index}><p><a href={content[index].link}>{content[index].inhalt}</a><br />{content[index].textunter}</p></React.Fragment>)
                } else {
                    return(<React.Fragment key={index}><p><a href={content[index].link}>{content[index].inhalt}</a></p></React.Fragment>)
                }
            case "bild":
                return(<React.Fragment key={index}><img className="in-text-img" src={"./assets/"+content[index].bild+".jpg"} alt={content[index].alt}/></React.Fragment>)
        }
    }

    render(){
        const content = this.props.children;
        const result = content.map((data, index) =>
            this.evaluateData(data.typ,index,content)
        )
        return(result)
    }
}


class ModalInformation extends React.Component{
    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({show: nextProps.modalExpanded});
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log('getDerivedStateFromProps method is called');
    //     console.log("props:"+props.modalExpanded);
    //     console.log("state:"+state.show);
    //     return {show: props.modalExpanded};
    //  }


    render(){
        return(
            <React.Fragment>
                <a className="link-more" onClick={() => this.setState({show: true})}>  mehr<span className="material-icons">launch</span></a>
                <Modal
                show={this.state.show}
                onHide={() => this.setState({show: false})}
                >
                    <Modal.Header className="card-header sticky-top">
                        <Modal.Title id="example-custom-modal-styling-title">
                            {this.props.title}
                        </Modal.Title>
                        <button className="close" type="button" onClick={() => this.setState({show: false})}>
                            <span className="material-icons">close</span>
                        </button>
                        </Modal.Header>
                    <Modal.Body>
                        {this.props.children}
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

class RenderParagraph extends React.Component{
    render(){
    const [firstLine, ...rest] = this.props.children.split('\n');
    return (
      <p>
        <SpecialHtmlSigns>{firstLine}</SpecialHtmlSigns>
        {rest.map((line,key) => 
          <React.Fragment key={key}>
            <br />
            <SpecialHtmlSigns>{line}</SpecialHtmlSigns>
          </React.Fragment>
        )}
        {this.props.link}
      </p>
    )
    }
}

function SpecialHtmlSigns(props, context){
    const [firstLine, ...rest] = props.children.split('&nbsp;');
    return (
    <React.Fragment>
        <NonBreakingHyphan>{firstLine}</NonBreakingHyphan>
        {rest.map((line,key) => 
          <React.Fragment key={key}>
            &nbsp;
            <NonBreakingHyphan>{line}</NonBreakingHyphan>
          </React.Fragment>
        )}
      </React.Fragment>
    )
}

function NonBreakingHyphan(props, context){
    const [firstLine, ...rest] = props.children.split('&#8209;');
    return (
    <React.Fragment>
        {firstLine}
        {rest.map((line,key) => 
          <React.Fragment key={key}>
            &#8209;
            {line}
          </React.Fragment>
        )}
      </React.Fragment>
    )
}

class ListContent extends React.Component{
    render(){
        const content = this.props.content;
        const result = content.map((text, index) =>
        <li key={index}>{text}</li>);
    return(<ul>{result}</ul>);
    }
}

class ContentOnlyImg extends React.Component{
    render(){
            return(<Card className="only-img"><Card.Img alt={this.props.alt} src={"./assets/"+this.props.src+".jpg"} /></Card>)
    }
}
